import {
  configure,
  defineRule,
  ErrorMessage,
  Field as VeeField,
  Form as VeeForm,
} from 'vee-validate';
import {
  alpha_spaces as alphaSpaces,
  confirmed,
  email,
  max,
  min,
  required,
  is,
} from '@vee-validate/rules';
import i18n from '@/includes/i18n';

export default {
  install(app) {
    app.component('VeeForm', VeeForm);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('required', required);
    defineRule('alphaSpaces', alphaSpaces);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('email', email);
    defineRule('confirmed', confirmed);
    defineRule('is', is);
    defineRule('dependent', (value, [target], ctx) => ctx.form[target] !== true || value.length > 0);
    defineRule('phoneNumber', (value) => {
      if (value) {
        const E164_REGEX = /^\+[1-9]\d{1,14}$/;
        return E164_REGEX.test(value);
      }

      return !value || value.length > 0;
    });

    configure({
      generateMessage: (ctx) => {
        const messages = {
          required: i18n.global.t('VeeValidations.required'),
          min: i18n.global.t('VeeValidations.min', { length: ctx.rule.params }),
          max: i18n.global.t('VeeValidations.max', { length: ctx.rule.params }),
          alphaSpaces: i18n.global.t('VeeValidations.alphaSpaces'),
          email: i18n.global.t('VeeValidations.email'),
          confirmed: i18n.global.t('VeeValidations.confirmed'),
          is: i18n.global.t('VeeValidations.is'),
          phoneNumber: i18n.global.t('VeeValidations.phoneNumber'),
          dependent: i18n.global.t('VeeValidations.dependent'),
        };

        return messages[ctx.rule.name] ? messages[ctx.rule.name] : 'This field is required!';
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: true,
      validateOnModelUpdate: true,
    });
  },
};
