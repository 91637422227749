import { createStore } from 'vuex';
import auth from '@/store/modules/auth';
import content from '@/store/modules/content';
import kss from '@/store/modules/kss';
import teamManagement from '@/store/modules/teamManagement';
import questionnaireManagement from '@/store/modules/questionnaireManagement';
import dashboard from '@/store/modules/dashboard';

export default createStore({
  modules: {
    auth,
    content,
    kss,
    teamManagement,
    questionnaireManagement,
    dashboard,
  },
});
