<template>
  <div class="language-container">
    <div class="com">
      <div class="dropdown">
        <div>
          <div class="list-items-selected" @click.prevent="toggleShowOptions">
            <div class="item-selected" id="yourself_right">
              <span class="item-text" id="txt_yourself_right">
                <span :class="getFlagIcon(selectedLanguage)"/>
              </span>
            </div>
          </div>
        </div>
        <div class="dropdown-container" v-if="getShowOptions">
          <ul class="list-items" id="list-items">
            <li class="item" v-for="lang in languageOptions"
                :key="lang"
                @click.prevent="setLanguage(lang)">
              <span :class="getFlagIcon(lang)"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageComponent',
  data() {
    return {
      showOptions: false,
      languageOptions: [
        'en',
        'de',
      ],
      selectedLanguage: this.$store.state.auth.lang,
    };
  },
  computed: {
    getShowOptions() {
      return this.showOptions;
    },
  },
  methods: {
    toggleShowOptions() {
      this.showOptions = !this.showOptions;
    },
    getFlagIcon(key) {
      if (key === 'de') {
        // German flag
        return 'fi fi-de';
      }

      // Great Britain flag
      return 'fi fi-gb';
    },
    setLanguage(lang) {
      this.selectedLanguage = lang;
      this.$root.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      this.$store.commit('setLanguage', lang);
      this.toggleShowOptions();
    },
  },
};
</script>

<style scoped>
.box .content .oim h2 {
  font-size: 23px;
  padding: 50px 0 10px;
}

.dropdown-container {
  position: absolute;
}

.language-container {
  display: flex;
  width: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
  /*width: 100%;*/
  margin: 0 auto 47px;
  justify-content: center;
  align-items: center;
}

.language-container .com {
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
  /*width: 100%;*/
  /*max-width: 300px;*/
  /*height: 200px;*/
  cursor: pointer;
  padding-bottom: 8px;
  flex-grow: 2;
}

.language-container .middle {
  text-align: center;
  padding: 0 3rem;
  width: fit-content;
}

.language-container .middle h3 {
  font-size: 20px;
}

.language-container .dropdown {
  width: fit-content;
}

.language-container .dropdown .list-items {
  display: block;
  max-height: 100px;
  overflow-y: scroll;
  width: fit-content;
  /*position: fixed;*/
  z-index: 10;
}

.language-container .dropdown .list-items-selected {
  display: block;
  max-height: 100px;
  overflow-y: scroll;
  width: fit-content;
  /*position: fixed;*/
  z-index: 10;
}

.language-container .dropdown .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.language-container .dropdown .list-items-selected::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.language-container .dropdown .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
  pointer-events: none;
}

.language-container .dropdown .list-items-selected::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
  pointer-events: none;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.list-items-selected {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.list-items-selected:hover {
  background-color: var(--color-orange);
}

.list-items .item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item-selected {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  /*width: 100%;*/
  /*height: 40px;*/
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
  background-color: var(--color-orange);
}
</style>
