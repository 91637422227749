<template>
  <div class="inner-header" id="tpl_header" style="display: block;">
    <div class="logo-container">
      <router-link to="/">
        <img src="@/assets/logo_transparent.png" alt="" class="platform-logo" id="img_logo">
      </router-link>
    </div>
    <!--------------------- NAVBAR ------------------>
    <nav id="navbar-container">
      <div class="navbar-container" id="tpl_navbar">
        <input type="checkbox" v-model="burgerMenu">
        <div class="burger">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
        <ul class="nav-items">
          <!--------------------- NAVITEMS ------------------>
          <div class="menu-links">
            <li style="display: none">
              <a href=""> {{ $t('HeaderComponent.oiMindset') }}
                <font-awesome-icon icon="fa-angle-down"/>
              </a>
              <ul>
                <li>
                  <a href="" class="oim-evaluation" id="oim_evaluation">
                    {{ $t('HeaderComponent.evaluation') }}
                  </a>
                </li>
                <li>
                  <a href="" class="oim-creator" id="oim_creator">
                    {{ $t('HeaderComponent.administration') }}
                  </a>
                </li>
                <li>
                  <a href="" class="oim-questioncatalog" id="oim_questioncatalog">
                    {{ $t('HeaderComponent.questionCatalog') }}
                  </a>
                </li>
              </ul>
            </li>
            <li id="kss_creator" style="display: block;">
              <router-link to="/kss-creator" id="kss_creator_link"
                           @click.prevent="burgerMenu= false">
                {{ $t('HeaderComponent.kssCreator') }}
              </router-link>
            </li>
            <li @click.prevent="toggleResultsMenu">
              <a class="results">
                {{ $t('HeaderComponent.results') }}
                <font-awesome-icon icon="fa-angle-down"/>
              </a>
              <ul v-if="isDesktop || showResultDropdown">
                <li>
                  <router-link to="/dashboard" class="own-results" id="own_results"
                               @click.prevent="burgerMenu = false">
                    {{ $t('HeaderComponent.yourOwnResults') }}
                  </router-link>
                </li>
                <li v-if="getPermissions.admin ||
               getPermissions.companySuperuser ||
               getPermissions.teamLeader">
                  <router-link to="/team-dashboard" class="team-results" id="team_results"
                               @click.prevent="burgerMenu = false">
                    {{ $t('HeaderComponent.teamResults') }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/knowledge-database" class="results"
                           @click.prevent="burgerMenu= false">
                {{ $t('HeaderComponent.knowledgeDatabase') }}
              </router-link>
              <ul v-if="false">
                <li v-if="getPermissions.teamLeader">
                  <router-link to="/knowledge-database" class="team-results" id="knowledge"
                               @click.prevent="burgerMenu= false">
                    {{ $t('HeaderComponent.omindset') }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <a href="" class="teamspace" id="teamspace_setup">
                {{ $t('HeaderComponent.teamspaceSetup') }}
              </a>
            </li>
            <li>
              <a href="" class="configuration" id="configuration">
                {{ $t('HeaderComponent.configuration') }}
              </a>
            </li>
            <li
              v-if="getPermissions.admin ||
               getPermissions.companySuperuser ||
               getPermissions.teamLeader"
              id="teamadministration">
              <router-link to="/team-administration" id="team_administration_link"
                           @click.prevent="burgerMenu= false">
                {{ $t('HeaderComponent.teamadministration') }}
              </router-link>
            </li>
            <li v-if="getPermissions.admin" id="admin">
              <router-link to="/admin" id="admin_link">
                {{ $t('HeaderComponent.admin') }}
              </router-link>
            </li>
            <li>
              <a href="" id="btn_logout" class="logout" @click.prevent="logout">
                <font-awesome-icon icon="fa-power-off"/>
                {{ $t('HeaderComponent.logout') }}
              </a>
            </li>
          </div>
        </ul>
      </div>
    </nav>
    <!--------------------- PROFILE ------------------>
    <div class="profile">
      <ul>
        <li>
          <div class="profile-photo">
            <img :src="getProfilePicture" alt="" class="photo">
          </div>
          <ul>
            <li>
              <div class="handle" id="div_menuProfil">
                <h4> {{ getName }}</h4>
                <p class="text-muted">{{ getEmail }}</p>
              </div>
            </li>
            <li>
              <div class="link">
                <router-link to="/edit-profile" class="go-to-profile">
                  {{ $t('HeaderComponent.goToProfile') }}
                  <font-awesome-icon icon="fa-arrow-right"/>
                </router-link>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      user: this.$store.state.auth.user,
      burgerMenu: false,
      showResultDropdown: false,
      isDesktop: false,
    };
  },
  computed: {
    getName() {
      return this.user?.name ?? '';
    },
    getEmail() {
      return this.user?.email ?? '';
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
    getRefreshValue() {
      return this.$store.getters.getRefreshValue;
    },
    getProfilePicture() {
      // eslint-disable-next-line global-require
      if (this.user.picture_profile) {
        return `${this.user.picture_profile}?_${this.getRefreshValue}`;
      }

      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/auth');
    },
    toggleResultsMenu() {
      this.showResultDropdown = !this.showResultDropdown;
    },
  },
  mounted() {
    this.isDesktop = window.innerWidth > 1124;
  },
  created() {
    // add event listener for window resize
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 1124;
    });
  },
};
</script>

<style scoped>

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  width: 100%;
}

.navbar-container {
  opacity: 0.97;
  z-index: 10;
}

.navbar-container input[type="checkbox"],
.navbar-container .burger {
  display: block;
  cursor: pointer;
}

nav .nav-items {
  display: flex;
  justify-content: space-around;
  transform: translateX(1);
}

nav .nav-items .menu-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

nav .nav-items .menu-links li {
  position: relative;
  display: inline-block;
}

nav .nav-items .menu-links li a {
  display: inherit;
  padding: 0 15px;
  line-height: 40px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.11px;
  color: var(--color-black);
  transition: color 0.3s ease;
}

nav .nav-items .menu-links ul li a {
  font-size: 16px;
  font-weight: 500;
}

nav .nav-items .menu-links li a:hover {
  color: var(--color-orange);
}

nav .nav-items .menu-links li a.active {
  color: var(--color-fulvous);
}

.fa-angle-down {
  padding-left: 5px;
}

.fa-power-off {
  padding-right: 10px;
}

nav .nav-items .menu-links ul {
  position: absolute;
  top: 40px;
  display: none;
}

nav .menu-links ul li {
  width: 250px;
  float: none;
  display: list-item;
  position: relative;
}

nav .nav-items .menu-links li a.teamspace { /*1. Stufe des Menüs*/
  display: none;
}

nav .nav-items .menu-links li a.configuration {
  display: none;
}

nav .menu-links ul li a.own-results { /*2. Stufe des Menüs*/
  display: block;
}

nav .menu-links ul li a.oim-creator {
  display: none;
}

nav .menu-links ul li a.oim-questioncatalog {
  display: none;
}

nav .menu-links ul li a.kss-creator {
  display: none;
}

.navbar-container input[type="checkbox"] {
  position: absolute;
  display: block;
  align-items: center;
  height: 40px;
  width: 40px;
  top: 27px;
  right: 20px;
  z-index: 10;
  opacity: 0;
}

.navbar-container .burger {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width: 38px;
  margin-top: 5px;
  right: 20px;
  z-index: 2;
  display: none;
  cursor: pointer;
}

.navbar-container .burger .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #333;
}

/*----------------- Animation Burger Button -----------------*/
.navbar-container .burger .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.navbar-container .burger .line2 {
  transition: transform 0.2s ease-in-out;
}

.navbar-container .burger .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

nav input[type="checkbox"]:checked ~ .nav-items {
  transform: translateX(0);
}

nav input[type="checkbox"]:checked ~ .burger .line1 {
  transform: rotate(35deg);
}

nav input[type="checkbox"]:checked ~ .burger .line2 {
  transform: scaleY(0);
}

nav input[type="checkbox"]:checked ~ .burger .line3 {
  transform: rotate(-35deg);
}

@media (min-width: 1124px) {
  nav .menu-links li:hover > ul {
    width: 250px;
    /*  height: 100%;
     max-height: 500px; */
    display: block;
    border-radius: 1rem;
    /* background-color: var(--color-white);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
    z-index: 10;
  }
}

@media screen and (max-width: 1124px) {
  .nav-items {
    position: absolute;
    right: 0;
    height: 92vh;
    top: 1vh;
    background: var(--color-milk);
    width: 40%;
    align-items: center;
    margin: -11px 0 0 0;
    right: -10px;
    box-shadow: 5px 0px 15px 3px #aaa;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }

  .nav-items .menu-links {
    display: flex;
    flex-direction: column;
    height: 70%;
    margin-left: 2rem;
  }

  nav .nav-items .menu-links ul {
    position: relative;
    top: 0px;
    left: 20px;
    display: none;
  }

  nav .menu-links li:hover > ul {
    /* width: 250px; */
    cursor: pointer;
    display: block;
    background-color: transparent;
    box-shadow: none;
    /* margin-left: 7rem; */
  }

  nav .nav-items .menu-links li {
    /* position: relative; */
    display: flow-root;
  }

  .navbar-container .burger {
    margin-top: -15px;
    display: flex;
  }

  nav .nav-items .menu-links li a {
    font-size: 18px;
  }

  nav .nav-items .menu-links ul li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .nav-items {
    width: 55%;
  }
}

@media screen and (max-width: 400px) {
  .nav-items {
    width: 100%;
  }
}

/*------------------- PROFIL -----------------------*/
.inner-header .profile {
  display: flex;
  min-height: 6px;
}

.inner-header .profile ul {
  padding: 0;
  margin: 0;
  float: right;
  margin-right: 45px;
}

.inner-header .profile ul li {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.inner-header .profile ul li div {
  height: 100%;
  display: block;
  margin-top: 5px;
}

.inner-header .profile ul li div:hover {
  transform: scale(1.05);
}

.inner-header .profile ul ul {
  position: absolute;
  top: 63px;
  left: -190px;
  display: none;
}

.inner-header .profile ul li:hover > ul {
  display: block;
  border-radius: 1rem;
  background-color: var(--color-white);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.inner-header .profile ul ul li {
  width: 100%;
  float: none;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding: 0px 20px;
}

.inner-header .profile ul li .handle {
  padding-top: 10px;
  padding-left: 0;
  padding-right: 10px;
  padding-bottom: 10px;
}

.inner-header .profile ul li .handle:hover {
  transform: scale(1);
}

.inner-header .profile ul li .handle h4 {
  padding: 0;
}

.inner-header .profile ul li .handle p {
  padding: 0;
}

.inner-header .profile ul li .link {
  padding-top: 10px;
  padding-left: 0;
  padding-right: 10px;
  padding-bottom: 10px;
}

.inner-header .profile ul li .link .go-to-profile {
  color: var(--color-dark);
}

.inner-header .profile ul li .link .go-to-profile:hover {
  color: var(--color-orange);
}

.profile-photo {
  width: 60px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.fa-arrow-right {
  margin: -3px 5px;
  height: 15px;
  width: 15px;
}

.text-muted {
  color: var(--color-disabled);
}

.logout li {
  padding: 10px 20px;
}

.logo-container {
  height: 100%;
  display: table;
  float: left;
}

.logo-container .platform-logo {
  width: 106px;
  height: auto;
  padding-top: 5px;
  display: table-cell;
  vertical-align: center;
}

@media screen and (max-width: 1124px) {
  .inner-header .profile ul {
    padding: 0;
    margin: 0;
    float: right;
    margin-right: 50px;
  }

  .inner-header .profile ul ul {
    position: absolute;
    top: 65px;
    left: -141px;
    display: none;
  }
}

@media screen and (max-width: 290px) {
  .inner-header .profile ul ul {
    left: -135px;
  }
}

</style>
