<template>
  <div class="container">
    <div class="forms">
      <div class="pass">
        <router-link class="link" to="/auth/register">
          <i>
            <font-awesome-icon icon="fa-arrow-left"/>
          </i>
          {{ $t('AuthTeamComponent.back') }}
        </router-link>
      </div>
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('AuthTeamComponent.chooseYourTeam') }}</h1>
      <vee-form @submit="next">
        <div class="select-container">
          <ul class="list-items" id="tpl_teams-container">
            <li :class="{ item: !isChecked(team),
             'item checked': isChecked(team)}"
                v-for="team in teams" :key="team"
                @click.prevent="toggleSelection(team)">
              <span class="item-text">
                {{ team.name }}
              </span>
              <span class="checkbox">
                <i class="check-icon">
                  <font-awesome-icon icon="fa-circle-check"/>
                </i>
              </span>
            </li>
          </ul>
        </div>
        <button type="submit" class="button" name="regist" id="btn_regist">
          {{ $t('AuthTeamComponent.next') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthTeamComponent',
  computed: {
    teams() {
      return this.$store.getters.getTeams;
    },
  },
  methods: {
    toggleSelection(team) {
      this.$store.commit('toggleTeamSelection', team._id);
    },
    isChecked(team) {
      return this.$store.state.content.selectedTeams.includes(team._id);
    },
    next() {
      this.$router.push('tag-selection');
    },
  },
  mounted() {
    this.$store.dispatch('getTeams');
  },
};
</script>

<style scoped>

.container .forms {
  padding: 30px;
}

/*---------------- GENERAL ------------------*/

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

h1 {
  text-align: center;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
  padding: var(--card-padding) 0 var(--card-padding);
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);
}

.pass i {
  padding: 0 .5rem;
}

.select-container {
  position: relative;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.select-container .list-items {
  width: 100%;
  max-height: 250px;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.select-container .list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.select-container .list-items .item:hover {
  transform: scale(1.02);
}

.select-container .list-items .item .item-text {
  display: flex;
  align-items: center;
}

.select-container .list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.select-container .list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.item.checked .check-icon {
  transform: scale(1);
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=submit]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

</style>
