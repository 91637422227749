import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function checkIfPasswordResetRequested(to, from, next) {
  if (store.state.auth.passwordResetRequested) {
    next();
  } else {
    next('/auth/password-reset');
  }
}
