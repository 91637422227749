<template>
  <div class="input-container" @click="handleContainerClick">
    <div class="pill-container">
      <div v-for="(email, index) in emails" :key="index" class="email-pill"
           @click="editEmail(index)">
        {{ email }}
        <span class="close" @click.prevent="removeEmail(index)">
          <font-awesome-icon icon="fa-trash-can"/>
        </span>
      </div>
      <input class="email-input" type="text"
             id="email-input" ref="emailInput"
             :placeholder="$t('KssEmailInviteComponent.placeholder.enterEmail')"
             v-model="email" @keydown.delete="deleteEmail"
             @keydown.enter="addEmail" @keydown.space="addEmail"
             @blur="addEmail"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KssEmailInviteComponent',
  data() {
    return {
      email: '',
    };
  },
  computed: {
    emails() {
      return this.$store.getters.getInviteEmails;
    },
  },
  methods: {
    addEmail() {
      if (!this.email) {
        return;
      }
      const newEmails = this.email.split(',')
        .map((email) => email.trim()
          .toLowerCase());
      const validEmails = newEmails.filter((email) => this.validateEmail(email));
      this.$store.commit('addInviteEmail', validEmails);
      if (validEmails.length < newEmails.length) {
        const invalidEmails = newEmails.filter((email) => !this.validateEmail(email));
        this.email = invalidEmails.join(', ');
      } else {
        this.email = '';
      }
    },
    removeEmail(index) {
      this.$store.commit('removeInviteEmail', index);
    },
    editEmail(index) {
      this.email = this.emails[index];
      this.$store.commit('removeInviteEmail', index);
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    handleContainerClick(event) {
      const { target } = event;
      const isInput = target === this.$refs.emailInput;
      const isPillRemove = target.classList.contains('close');
      if (!isInput && !isPillRemove) {
        this.$refs.emailInput.focus();
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    deleteEmail() {
      if (this.email === '' && this.emails.length > 0) {
        this.$store.commit('popInviteEmail');
        this.email = '';
      }
    },
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
};
</script>

<style>
.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.email-pill {
  display: flex;
  align-items: center;
  background-color: #ddd;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
}

.email-pill .close {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
</style>
