import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    selectedDate: null,
    selectedHours: null,
    selectedMinutes: null,
    archivedTasks: [],
    createdTasks: [],
    kssParticipants: [],
    kssTeams: [],
    newMeetingSubject: '',
    newDescription: '',
    errorFlag: false,
    isRecurringTask: false,
    recurringType: 'daily',
    recurringSubtype: 'days',
    recurringEveryX: 1,
    recurringDays: [],
    showRuntime: true,
    showCalendar: true,
    inviteEmails: [],
  },
  getters: {
    getSelectedDate: (state) => state.selectedDate,
    getKssParticipants: (state) => state.kssParticipants,
    getKssTeams: (state) => state.kssTeams,
    getSelectedKssParticipantCount:
      (state) => state.kssParticipants.filter((e) => e.isChecked).length,
    getRecurringDays: (state) => state.recurringDays,
    getIsRecurringTask: (state) => state.isRecurringTask,
    getInviteEmails: (state) => state.inviteEmails,
    getCurrentArchivedTasks: (state) => state.archivedTasks,
    getCurrentCreatedTasks: (state) => state.createdTasks,
  },
  mutations: {
    setShowCalendar: (state, value) => {
      state.showCalendar = value;
    },
    setShowRuntime: (state, value) => {
      state.showRuntime = value;
    },
    setSelectedDate: (state, date) => {
      state.selectedDate = new Date(date);
    },
    setSelectedTime: (state, time) => {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      state.selectedHours = Number(hours);
      state.selectedMinutes = Number(minutes);
    },
    setKssTeams: (state, data) => {
      data.forEach((el) => {
        state.kssTeams.push({
          _id: el._id,
          name: el.name,
          isChecked: false,
        });
      });
    },
    setKssParticipants: (state, data) => {
      data.forEach((el) => {
        state.kssParticipants.push({
          _id: el.id,
          name: el.name,
          teams: el.teams,
          isChecked: false,
        });
      });
    },
    setArchivedTasks: (state, data) => {
      state.archivedTasks = data;
    },
    setCreatedTasks: (state, data) => {
      state.createdTasks = data;
    },
    setNewSubject: (state, newSubject) => {
      state.newMeetingSubject = newSubject;
    },
    setNewDescription: (state, newDescription) => {
      state.newDescription = newDescription;
    },
    setIsRecurringTask: (state, value) => {
      state.isRecurringTask = value;
    },
    setRecurringType: (state, value) => {
      state.recurringType = value;
    },
    setRecurringSubtype: (state, value) => {
      state.recurringSubtype = value;
    },
    setRecurringEveryX: (state, value) => {
      state.recurringEveryX = value;
    },
    initRecurringDays: (state) => {
      state.recurringDays = [
        {
          rSchName: 'MO',
          isSelected: false,
        },
        {
          rSchName: 'TU',
          isSelected: false,
        },
        {
          rSchName: 'WE',
          isSelected: false,
        },
        {
          rSchName: 'TH',
          isSelected: false,
        },
        {
          rSchName: 'FR',
          isSelected: false,
        },
        {
          rSchName: 'SA',
          isSelected: false,
        },
        {
          rSchName: 'SU',
          isSelecte: false,
        },
      ];
    },
    selectRecurringDate: (state, index) => {
      state.recurringDays[index].isSelected = !state.recurringDays[index].isSelected;
    },
    updateKssTeamSelection: (state, index) => {
      state.kssTeams[index].isChecked = !state.kssTeams[index].isChecked;
      state.kssParticipants.forEach((kssParticipant, i) => {
        if (kssParticipant.teams.includes(state.kssTeams[index]._id)) {
          state.kssParticipants[i].isChecked = state.kssTeams[index].isChecked;
        }
      });
    },
    updateKssParticipantSelection: (state, index) => {
      state.kssParticipants[index].isChecked = !state.kssParticipants[index].isChecked;
    },
    addInviteEmail: (state, emails) => {
      if (emails !== undefined) {
        state.inviteEmails.push(...emails);
      }
    },
    removeInviteEmail: (state, index) => {
      state.inviteEmails.splice(index, 1);
    },
    popInviteEmail: (state) => {
      state.inviteEmails.pop();
    },
    removeKssParticipants: (state) => {
      state.kssParticipants = [];
    },
    removeArchivedTasks: (state) => {
      state.archivedTasks = [];
    },
    removeCreatedTasks: (state) => {
      state.createdTasks = [];
    },
    removeKssTeams: (state) => {
      state.kssTeams = [];
    },
    resetInviteEmail: (state) => {
      state.inviteEmails = [];
    },
    resetKssFormValues: (state) => {
      state.selectedMinutes = null;
      state.selectedHours = null;
      state.selectedDate = null;
      state.newDescription = '';
      state.newMeetingSubject = '';
    },
    resetKssRecurringTaskValues: (state) => {
      state.recurringEveryX = 1;
      state.recurringType = 'daily';
      state.recurringSubtype = 'days';
      state.isRecurringTask = false;
    },
  },
  actions: {
    async getArchivedTasks({ commit }) {
      const { data } = await NetworkContent.getArchivedTasks();
      commit('setArchivedTasks', data.tasks);
    },
    async getCreatedTasks({ commit }) {
      const { data } = await NetworkContent.getCreatedTasks();
      commit('setCreatedTasks', data.tasks);
    },
    async getKssTeams({ commit }) {
      const { data } = await NetworkContent.getTeams();
      commit('setKssTeams', data.teams);
    },
    async getKssParticipants({ commit }) {
      const { data } = await NetworkContent.getParticipants();
      commit('setKssParticipants', data.users);
    },
    async createKssTask({
      commit,
      dispatch,
    }, data) {
      await NetworkContent.createKssTask(data);
      commit('resetKssFormValues');
      commit('removeKssParticipants');
      commit('removeKssTeams');
      commit('resetInviteEmail');
      commit('resetKssRecurringTaskValues');
      commit('initRecurringDays');
      dispatch('getCreatedTasks');
      dispatch('getArchivedTasks');
      dispatch('getKssTeams');
      dispatch('getKssParticipants');
    },
    async deleteKssTask({ dispatch }, data) {
      await NetworkContent.deleteKssTask(data);
      dispatch('getCreatedTasks');
    },
  },
};
