<template>
  <!----------- TASK REMINDER --------------->
  <div class="single-popup" id="tpl_taskReminder_popup">
    <div class="text">
      <div class="headline">
        <h3>
          <span id="tpl_taskReminder_popup_firstName">
          {{ notification.content.firstName }}
          </span>
          <span id="tpl_taskReminder_popup_header">
          {{ $t('TaskReminderNotificationComponent.taskiWillExpireInfoMessage') }} </span>
          <span id="tpl_taskReminder_popup_timePeriod">
            {{ getTimeRemaining }}.
          </span>
        </h3>
        <div class="close" id="btn_close">
          <i @click.prevent="deactivate()">
            <font-awesome-icon icon="fa-xmark"/>
          </i>
        </div>
      </div>
      <div class="description">
        <p>
          <span id="tpl_taskReminder_popup_text">
            {{ $t('TaskReminderNotificationComponent.finishIt') + ' ' }}
          </span>
          <router-link :to="getLink" class="link" id="tpl_newTask_popup_link">
             {{ $t('TaskReminderNotificationComponent.rightAway') }}
          </router-link>
          .
        </p>
      </div>
      <div class="footline">
        <span class="omind">
          {{ $t('TaskReminderNotificationComponent.footlineOMIND') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['notification'],
  name: 'TaskReminderNotificationComponent',
  data() {
    return {
      minutesRemaining: null,
      secondsRemaining: null,
    };
  },
  computed: {
    getLink() {
      // TODO oim redirect to intro ?
      return `/evaluation/questionnaire?id=${this.notification.assessment}`;
    },
    getTimeRemaining() {
      if (this.minutesRemaining !== 0) {
        return `${this.minutesRemaining} ${this.$t('TaskReminderNotificationComponent.minutesShort')}`;
      }
      return `${this.secondsRemaining} ${this.$t('TaskReminderNotificationComponent.secondsShort')}`;
    },
  },
  methods: {
    deactivate() {
      this.$store.dispatch('deactivatePopUp', this.notification._id);
    },
  },
  mounted() {
    const localDate = new Date(this.notification.date_until);
    const countDownDate = new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
    ).getTime();

    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      this.minutesRemaining = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
        this.$store.dispatch('deactivatePopUp', this.notification._id);
      }
    }, 1000);
  },
};
</script>

<style scoped>
.single-popup {
  display: block;
  padding: 10px 16px;
  margin: 7px 0;
  border-radius: var(--border-radius);
  box-shadow: 5px 10px 8px var(--color-light);
  background-color: var(--color-dark);
}

.single-popup .text {
  padding: 20px;
  color: white;
}

.single-popup .text .headline {
  display: flex;
}

.single-popup .text .headline .close {
  margin-top: -16px;
  margin-right: -16px;
  cursor: pointer;
}

.single-popup .text .headline .close:hover {
  color: var(--color-orange);
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  color: white;
}

.single-popup .text .footline {
  font-size: 12px;
  float: right;
  color: var(--color-disabled);
}

summary {
  padding: 20px 0;
  color: var(--color-orange);
  cursor: pointer;
}

.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.accordeon {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@media screen and (min-width: 431px) and (max-width: 500px) {
  .single-popup {
    border-radius: 1rem;
  }

  .single-popup .text {
    padding: 10px;
    text-align: center;
  }

  .single-popup .text .headline h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .single-popup .text .headline .close {
    margin-top: -14px;
    margin-right: -14px;
  }

  .single-popup .text .description {
    font-size: 14px;
  }

  .single-popup .text .footline {
    font-size: 12px;
    float: right;
    color: var(--color-disabled);
  }

  .accordeon {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    font-size: 12px;
  }

  .accordeon::-webkit-scrollbar {
    width: 2px;
    margin-left: 5px;
    background: var(--color-white);
    border-radius: 0 8px 8px 0;
  }

  .accordeon::-webkit-scrollbar-thumb {
    background: var(--color-disabled);
    border-radius: 0 8px 8px 0;
  }
}

</style>
