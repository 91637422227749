<template>
  <div v-if="getProfileDashboardDesc" class="profil_description" id="div_profilDesc">
    <h1 id="div_profilDescHeadline">
      {{ $t('DashboardProfileComponent.yourMindsetInWords') }}
    </h1>
    <h3 class="head_strong_area" id="txt_greatestStrengths">
      {{ $t('DashboardProfileComponent.yourDimensions') }}
    </h3>
    <div class="row_line">
      <h2 class="h2 strength" id="txt_strength1">
        {{ getProfileDashboardDesc.first?.headline[this.locale] ?? '' }}
      </h2>
      <p class="description" id="txt_strength1Desc">
        {{ getProfileDashboardDesc.first?.text[this.locale] ?? '' }}
      </p>
    </div>
    <div class="row_line">
      <h2 class="h2 strength" id="txt_strength2">
        {{ getProfileDashboardDesc.behindFirst?.headline[this.locale] ?? '' }}
      </h2>
      <p class="description" id="txt_strength2Desc">
        {{ getProfileDashboardDesc.behindFirst?.text[this.locale] ?? '' }}
      </p>
    </div>
    <h3 class="head_development_area" id="txt_growthAreas">
      {{ $t('DashboardProfileComponent.youImprove') }}
    </h3>
    <div class="coln">
      <div class="row">
        <h2 class="h2 weak" id="txt_growthArea1">
          {{ getProfileDashboardDesc.last?.headline[this.locale] ?? '' }}
        </h2>
        <p class="description" id="txt_growthArea1Desc">
          {{ getProfileDashboardDesc.last?.text[this.locale] ?? '' }}
        </p>
      </div>
      <div class="our_tip">
        <div class="tip_head">
          <img src="@/assets/lamp.png" alt="">
          <h3 id="txt_devTip1">
            {{ $t('DashboardProfileComponent.developmentTip') }}
          </h3>
        </div>
        <div class="tip_content">
          <p id="txt_devTip1content">
            {{ getProfileDashboardDesc.last?.tip[this.locale] ?? '' }}
          </p>
        </div>
      </div>
    </div>
    <div class="coln">
      <div class="row">
        <h2 class="h2 weak" id="txt_growthArea2">
          {{ getProfileDashboardDesc.beforeLast?.headline[this.locale] ?? '' }}
        </h2>
        <p class="description" id="txt_growthArea2Desc">
          {{ getProfileDashboardDesc.beforeLast?.text[this.locale] ?? '' }}
        </p>
      </div>
      <div class="our_tip">
        <div class="tip_head">
          <img src="@/assets/lamp.png" alt="">
          <h3 id="txt_devTip2">
            {{ $t('DashboardProfileComponent.developmentTip') }}
          </h3>
        </div>
        <div class="tip_content">
          <p id="txt_devTip2content">
            {{ getProfileDashboardDesc.beforeLast?.tip[this.locale] ?? '' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardProfileComponent',
  data() {
    return {
      profileDashboardDesc: this.$store.state.content.profileDashboardDesc,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    getProfileDashboardDesc() {
      return this.$store.getters.getProfileDashboardDesc;
    },
  },
  mounted() {
    this.$store.dispatch('getDashboardProfileDescription');
  },
};
</script>

<style scoped>
.profil_description {
  margin-top: 70px;
  padding: var(--card-padding);
}

.head_development_area {
  margin-top: 30px;
}

.head_strong_area {
  margin-top: 30px;
}

.coln {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
  padding: 20px;
}

.row {
  display: block;
  flex: 1;
}

.row .h2 {
  padding-bottom: 10px;
}

.row .description {
  padding: 0;
}

.our_tip {
  display: block;
  margin-left: auto;
  flex: 1;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  padding: 20px;
  background-color: var(--color-orange);
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
}

.our_tip .tip_head {
  display: flex;
  align-items: center;
}

.our_tip .tip_head h3 {
  color: white;
  font-size: 20px;
}

.our_tip .tip_head img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.row_line {
  display: block;
  padding: 20px;
}

.row_line .strength {
  color: var(--color-orange);
}

.row .weak {
  color: var(--color-disabled);
}

</style>
