<template>
  <div class="team-container">
    <h1>{{ $t('HomeComponent.teamspace') }}</h1>
    <div class="teamspace-container-dummy" id="div_teamspace">
      <member-component v-for="member in teamspace" :key="member._id" :member="member"/>
    </div>
  </div>
  <div class="task-container">
    <h1>{{ $t('HomeComponent.myTasks') }}</h1>
    <div class="task-dummy" id="div_taskcontainer">
      <task-component v-for="task in assessments" :key="task._id" :task="task"/>
    </div>
  </div>
</template>

<script>
import MemberComponent from '@/components/Home/HomeComponent/MemberComponent.vue';
import TaskComponent from '@/components/Home/HomeComponent/TaskComponent.vue';
import * as push from '@/helpers/webpush/push';

export default {
  name: 'HomeComponent',
  data() {
    return {
      teamspace: this.$store.state.content.teamSpace,
      tasksGraber: '',
      permissionsGraber: '',
    };
  },
  components: {
    TaskComponent,
    MemberComponent,
  },
  computed: {
    assessments() {
      return this.$store.state.content.assessments;
    },
  },
  methods: {
    async getTeamspace() {
      await this.$store.dispatch('getTeamspace');
    },
    async getAssessmentsOverview() {
      await this.$store.dispatch('getAssessmentsOverview');
    },
  },
  async mounted() {
    await this.getTeamspace();
    await this.getAssessmentsOverview();
    if (push.checkServiceAvailable()) {
      await push.checkSubscription();
    }
    this.tasksGraber = setInterval(() => {
      this.$store.dispatch('getAssessmentsOverview');
    }, 3000);

    this.permissionsGraber = setInterval(() => {
      this.$store.dispatch('updatePermissionFlags');
    }, 30000);
  },
  unmounted() {
    this.$store.commit('removeTeamspace');
    this.$store.commit('removeAssessments');
    clearInterval(this.tasksGraber);
    clearInterval(this.permissionsGraber);
  },
};
</script>

<style>

/*------------------- TEAMSPACE TEMPLATE -----------------------*/
.team-container {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.teamspace-container-dummy {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  display: flex;
  justify-content: flex-start;
  height: 170px;
  gap: 0.3rem;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
  align-content: center;
}

.teamspace-container-dummy::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.team-container .teamspace-container-dummy .member {
  margin: 0.5rem 0;
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  position: relative;
}

.member .photo {
  width: 5rem;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.member .name {
  padding: 0 5px 5px;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}

/*------------------- TASKS TEMPLATE -----------------------*/
.task-container {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.task-container .task-dummy {
  display: block;
  justify-content: space-between;
  /*height: 500px;
  overflow-y: scroll;*/
  gap: 0.3rem;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  margin-top: 15px;
}

.task-container .task-dummy .task-card {
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
}

.task-container .task-dummy .task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-dummy .task-card .headline {
  display: flex;
  justify-content: space-between;
}

.task-dummy .task-card .headline h2 {
  font-size: 23px;
}

.task-dummy .task-card .headline .done i {
  font-size: 2rem;
  color: var(--color-disabled);
}

.time-rubber { /* Date-container on task-element, change color to: var(--color-disable)*/
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: max-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.time-rubber i {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.date {
  color: var(--color-white);
  padding: 5px;
  width: 100%;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  h1 {
    font-size: 25px;
  }

  .team-container {
    margin-bottom: 30px;
  }

  .teamspace-container-dummy {
    height: 170px;
  }

  .member .photo {
    width: 4rem;
  }

  .member .name {
    font-size: 15px;
  }

  .task-container {
    position: relative;
  }

  .task-dummy .task-card .headline h2 {
    font-size: 19px;
  }

  .task-dummy .task-card .headline .done i {
    font-size: 1.7rem;
  }

  .task-dummy .task-card .status {
    font-size: 15px;
  }

  .date {
    padding: 1px;
  }
}

@media (min-width: 1920px) {

  .team-container {
    margin-top: -2rem;
    width: 80vw;
  }

  .task-container {
    margin-top: 2rem;
    width: 80vw;
  }
}

</style>
