<template>
  <div class="avatar-upload">
    <font-awesome-icon class="edit-icon" icon="fa-pen" @click.prevent="toggleEdit"/>
    <div class="avatar-edit">
      <input type='file' ref="uploader" id="imageUpload" accept=".png, .jpg, .jpeg" placeholder=""
             @change="uploadImage"/>
      <label for="imageUpload" class="image-edit"></label>
    </div>
    <div class="avatar-preview">
      <div v-if="getCrop === ''" class="no-pic"></div>
      <img v-else :src="getCrop"
           alt=""/>
    </div>
  </div>
  <div class="editor-container" v-if="editMode">
    <vue-cropper class="cropper-container" ref="cropper"
                 :src="imageSource"
                 :aspect-ratio="1"
                 :crop-box-width="192"
                 :crop-box-height="192"
                 alt="Source Image"/>
    <div class="btns">
      <button @click="resetEditing"
              class="btn-editor"
              type="button"
              v-if="imageSource !== ''">
        {{ $t('ImageUploadComponent.reset')}}
      </button>
      <button @click="cancelEdit" class="btn-editor" type="button" v-if="imageSource !== ''">
        {{ $t('ImageUploadComponent.cancel')}}
      </button>
      <button @click="saveAndUploadImage" class="btn-editor" type="button"
              v-if="imageSource !== ''">
        {{ $t('ImageUploadComponent.save')}}
      </button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { useField } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ImageUploadComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  components: { VueCropper },
  data() {
    return {
      editMode: false,
      imageSource: '',
      cropImg: '',
      randomId: uuidv4(),
    };
  },
  computed: {
    getCrop() {
      if (this.cropImg) {
        return `${this.cropImg}?id=${this.randomId}`;
      }

      return '';
    },
  },
  methods: {
    uploadImage(e) {
      this.editMode = true;
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSource = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    resetEditing() {
      this.$refs.cropper.reset();
    },
    toggleEdit() {
      this.$refs.uploader.click();
    },
    cancelEdit() {
      this.editMode = false;
      this.$refs.uploader.value = null;
    },
    saveAndUploadImage() {
      this.$refs.uploader.value = null;
      this.editMode = false;
      this.$refs.cropper.getCroppedCanvas()
        .toBlob(async (blob) => {
          let url = this.cropImg;
          if (!url) {
            url = this.buildUrl(blob.type);
          }
          try {
            await this.$store.dispatch('uploadProfilePicture', {
              blob,
              url,
            });
            this.updateImage();
            this.$store.commit('setRefreshValue');
            await this.$store.dispatch('getMe');
            this.$store.commit('setToastNotification', {
              id: Math.random(),
              // Todo translate
              message: this.$t('ImageUploadComponent.successUpload'),
              type: 'success',
            });
          } catch (error) {
            this.$store.commit('setToastNotification', {
              id: Math.random(),
              message: this.$t('ImageUploadComponent.errorUpload'),
              type: 'error',
            });
          }
        });
      this.updateImage();
      this.$store.commit('setRefreshValue');
    },
    buildUrl(blobType) {
      const fileType = this.getFileType(blobType);
      const fileName = uuidv4() + fileType;
      const containerName = process.env.VUE_APP_AZURE_CONTAINER_NAME;
      const resourceName = process.env.VUE_APP_AZURE_STORAGE_RESOURCE_NAME;
      return `https://${resourceName}.blob.core.windows.net/${containerName}/${fileName}`;
    },
    getFileType(blobType) {
      switch (blobType) {
        case 'image/png':
          return '.png';
        case 'image/jpg':
          return '.jpg';
        default:
          return '.jpeg';
      }
    },
    updateImage() {
      this.randomId = uuidv4();
    },
  },
  created() {
    this.field = useField(this.name, undefined, {
      type: 'text',
    });
    this.cropImg = this.field.value;
  },
};
</script>

<style>
.editor-container {
  margin-bottom: 15px;
}

.edit-icon:hover {
  color: var(--color-orange);
  cursor: pointer;
}

/*------------ PROFILE EDIT ---------------*/
.edit-icon {
  float: right;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: none; /*inline-block*/
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2);
}

.avatar-upload .avatar-preview > img, .no-pic {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.btn-editor {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-editor:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*Overwrite cropper classes*/
.cropper-view-box {
  border-radius: 50%;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed {
  display: none;
}

</style>
