<template>
  <div class="container">
    <div class="forms">
      <div class="pass">
        <router-link class="link" to="/auth">
          <i>
            <font-awesome-icon icon="fa-arrow-left"/>
          </i>
          {{ $t('RegisterComponent.backToLogin') }}
        </router-link>
      </div>
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('RegisterComponent.createAccount') }}</h1>
      <vee-form :validation-schema="schema" @submit="saveRegisterData"
                :initial-values="initData"
                v-slot="{ errors }">
        <div class="input-field">
          <vee-field type="text" name="firstName" id="txt_first-name"
                     :placeholder="$t('RegisterComponent.placeholders.firstName')"
                     :class="{inputError : !!errors.firstName}"/>
        </div>
        <error-message class="error-message" name="firstName"></error-message>
        <div class="input-field">
          <vee-field type="text" name="lastName" id="txt_last-name"
                     :placeholder="$t('RegisterComponent.placeholders.lastName')"
                     :class="{inputError : !!errors.lastName}"/>
        </div>
        <error-message class="error-message"  name="lastName"></error-message>
        <div class="input-field">
          <vee-field type="text" name="email" id="txt_mail"
                     :placeholder="$t('RegisterComponent.placeholders.email')"
                     :class="{inputError : !!errors.email}"/>
        </div>
        <error-message class="error-message"  name="email"></error-message>
        <div class="input-field">
          <vee-field type="password" name="password" id="txt_password"
                     :placeholder="$t('RegisterComponent.placeholders.password')"
                     :class="{inputError : !!errors.password}"/>
        </div>
        <error-message class="error-message"  name="password"></error-message>
        <div class="input-field">
          <vee-field type="password" name="repeatPassword" id="txt_repeat-password"
                     :placeholder="$t('RegisterComponent.placeholders.repeatPassword')"
                     :class="{inputError : !!errors.repeatPassword}"/>
        </div>
        <error-message class="error-message"  name="repeatPassword"></error-message>
        <div class="input-field">
          <vee-field type="text" name="mobileNumber" id="txt_mobile"
                     :placeholder="$t('RegisterComponent.placeholders.mobileNumber')"
                     :class="{inputError : !!errors.mobileNumber}"/>
        </div>
        <error-message class="error-message"  name="mobileNumber"></error-message>
        <div class="agree-terms-container">
          <vee-field type="radio" name="tos" id="rd_terms-of-use" value="yes"/>
          <i18n-t id="" keypath="RegisterComponent.termsOfUseAndPrivacy">
            <router-link class="link"
                         to="/privacy"
                         target="_blank">
              {{ $t('RegisterComponent.tos') }}
            </router-link>
            <router-link class="link"
                         to="/privacy"
                         target="_blank">
              {{ $t('RegisterComponent.privacyPolicy') }}
            </router-link>
          </i18n-t>
        </div>
        <error-message class="error-message"  name="tos"></error-message>
        <language-component class="lang-component"/>
        <button type="submit" class="button" name="next" id="btn_next"
                :disabled="isSubmitting">
          {{ $t('RegisterComponent.next') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>

<script>
import LanguageComponent from '@/components/global/LanguageComponent.vue';

export default {
  name: 'RegisterComponent',
  components: { LanguageComponent },
  data() {
    return {
      schema: {
        firstName: 'required|alphaSpaces|min:3|max:100',
        lastName: 'required|alphaSpaces|min:3|max:100',
        email: 'required|email',
        password: 'required|min:8|max:100',
        repeatPassword: 'confirmed:@password',
        mobileNumber: 'phoneNumber|max:100',
        tos: 'required|is:yes',
      },
      initData: {
        tos: 0,
      },
      isSubmitting: false,
    };
  },
  methods: {
    async saveRegisterData(values) {
      try {
        this.isSubmitting = true;
        const {
          tos,
          repeatPassword,
          ...rest
        } = values;
        await this.$store.dispatch('validateRegister', rest);
        this.isSubmitting = false;
        if (this.dataValid) {
          this.$router.push('team-selection');
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
  computed: {
    dataValid() {
      return this.$store.state.auth.registerDataValid;
    },
  },
  mounted() {
    this.initData = this.$store.state.auth.registerData ?? { tos: 0 };
  },
};
</script>

<style scoped>
.lang-component {
  float: left;
  margin-top: 5px;
  position: absolute;
}

/*------------ GENERAL ----------------*/

h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 10px 0;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

/*------------ FORM ----------------*/
.container .forms {
  padding: 30px;
}

.error-message {
  margin-top: 2px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.forms .input-field {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #E7E7E7;

}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);

}

.pass i {
  padding: 0 .5rem;
}

@media (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

select {
  margin-bottom: 1rem;
  margin-top: 3rem;
  font-weight: 300;
  font-size: 18px;
}

.team option {
  font-size: 18px;
  padding: 5px;

}

/*------------------ BUTTONS ------------------*/
.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=submit]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

/*------------ AGREE TERMS ----------------*/
.agree-terms-container {
  margin: 1.5rem -1px 2rem;
  padding: 0px;
}

label {
  color: #1E2019;
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

</style>
