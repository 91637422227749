<template>
  <breadcrumbs-component :bread-crumbs="editProfileBreadCrumbs"/>
  <div class="edit-container">
    <div class="forms">
      <vee-form :validation-schema="schema" @submit="saveChanges"
                :initial-values="initData"
                v-slot="{ values, errors }"
                id="veeForm">
        <image-upload-component name="picture_profile"/>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.firstName}"
                     name="firstName"
                     id="txt_firstName"/>
          <label for="firstName" class="form-control">
            {{ $t('EditProfileComponent.firstName') }}
          </label>
        </div>
        <error-message class="error-message" name="firstName"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.lastName}"
                     name="lastName"
                     id="txt_lastName"
          />
          <label for="lastName" class="form-control">
            {{ $t('EditProfileComponent.lastName') }}
          </label>
        </div>
        <error-message class="error-message" name="lastName"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.email}"
                     name="email"
                     id="txt_mail"
          />
          <label for="email" class="form-control">
            {{ $t('EditProfileComponent.email') }}
          </label>
        </div>
        <error-message class="error-message" name="email"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.mobileNumber}"
                     name="mobileNumber"
                     id="txt_mobile"/>
          <label for="mobileNumber" class="form-control">
            {{ $t('EditProfileComponent.mobileNumber') }}
          </label>
        </div>
        <error-message class="error-message" name="mobileNumber"></error-message>
        <div class="check-sms" :class="{checked: values.SMSallowed}">
          <checkbox-component name="SMSallowed"
                              :modelValue="values.SMSallowed"
                              :value="values.SMSallowed"/>
          <label for="SMSallowed">
            {{ $t('EditProfileComponent.receieveSmsText') }}
          </label>
        </div>
        <div class="language">
          <span :class="getFlagIcon(values.preferredLanguage)"/>
          <label class="lang" for="preferredLanguage">
            {{ $t('EditProfileComponent.chooseLang') }}
          </label>
          <vee-field as="select" name="preferredLanguage" id="btn_language">
            <option value="en">English</option>
            <option value="de">Deutsch</option>
          </vee-field>
        </div>
      </vee-form>
    </div>
    <div class="btns">
      <button type="button" class="btn-cancel" name="cancel" id="btn_cancel">
        {{ $t('EditProfileComponent.cancel') }}
      </button>
      <button type="submit" form="veeForm" class="btn-save" name="save" id="btn_save"
              :disabled="isSubmitting">
        {{ $t('EditProfileComponent.saveChanges') }}
      </button>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import CheckboxComponent from '@/components/Home/Shared/CheckboxComponent.vue';
import ImageUploadComponent from '@/components/Home/Shared/ImageUploadComponent.vue';

export default {
  name: 'EditProfileComponent',
  components: {
    ImageUploadComponent,
    CheckboxComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      isSubmitting: false,
      schema: {
        firstName: 'required|alphaSpaces|min:3|max:100',
        lastName: 'required|alphaSpaces|min:3|max:100',
        email: 'required|email',
        mobileNumber: 'dependent:SMSallowed|phoneNumber|max:100',
      },
      editProfileBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/edit-profile',
          isActive: true,
          name: this.$t('BreadCrumbs.editProfile'),
        },
      ],
    };
  },
  computed: {
    initData() {
      return this.$store.getters.getMe;
    },
  },
  methods: {
    async saveChanges(values) {
      try {
        this.isSubmitting = true;
        this.$i18n.locale = values.preferredLanguage;
        this.$store.commit('setLanguage', values.preferredLanguage);
        await this.$store.dispatch('updateProfile', values);
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          // Todo translate
          message: this.$t('EditProfileComponent.profileSaved'),
          type: 'success',
        });
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('EditProfileComponent.errorSaved'),
          type: 'error',
        });
      }
    },
    getFlagIcon(key) {
      if (key === 'de') {
        // German flag
        return 'fi fi-de';
      }

      // Great Britain flag
      return 'fi fi-gb';
    },
  },
  mounted() {
    try {
      this.$store.dispatch('getMe');
    } catch (error) {
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: error.response.data.message,
        type: 'error',
      });
    }
  },
};
</script>

<style scoped>
.error-message {
  margin-top: 2px;
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

/*------------ CONTAINER ---------------*/
.edit-container {
  margin: 0 auto;
  position: relative;
}

.forms {
  max-width: 90vw;
  width: 600px;
  min-height: 540px;
  height: auto;
  display: block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-light);
}

input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding: 0.7rem var(--card-padding) 0.1rem;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all .3s;
  height: 3.5rem;
}

.input-field label {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all .3s;
  padding-left: 5px;
}

.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all .5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

.language {
  margin: 50px 0 20px;
  padding: 10px;
}

.language span {
  margin-right: 5px;
}

.language .lang {
  font-size: 1rem;
  color: #aaa;
  padding-right: 12px;
}

.language select {
  font-size: 1rem;
  border: 1px solid var(--color-orange);
  border-radius: 10px;
  padding: 5px 10px;
}

.check-sms {
  display: flex;
  cursor: pointer;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: block;
  position: relative;
  margin: 3rem 0;
  box-sizing: border-box;
}

.btn-save {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 10rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-save:disabled {
  cursor: not-allowed;
}

.btn-save:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-cancel {
  float: left;
  padding: 5px;
  font-size: 20px;
  width: 8rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
}

.btn-cancel:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}
</style>
