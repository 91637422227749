<template>
  <!----------------- BREADCRUMBS ----------------->
  <ul class="breadcrumbs">
    <li class="breadcrumbs-item" v-for="breadCrumb in breadCrumbs" :key="breadCrumb.name">
      <router-link :to="breadCrumb.goTo" :class="getLinkForClass(breadCrumb)">
        <b v-if="breadCrumb.isActive">
          {{ breadCrumb.name }}
        </b>
        <template v-else>{{ breadCrumb.name }}</template>
      </router-link>
    </li>

    <!--    <li class="breadcrumbs-item">-->
    <!--      <a href="" class="breadcrumbs-link" id="txt_breadcrumbsHome">HOME</a>-->
    <!--    </li>-->
    <!--    <li class="breadcrumbs-item">-->
    <!--      <a href="" class="breadcrumbs-link">KSS Tracker</a>-->
    <!--    </li>-->
    <!--    <li class="breadcrumbs-item">-->
    <!--<a class="breadcrumbs-link breadcrumbs-link-active">
    <b id="txt_creator">KSS Creator</b></a>-->
    <!--    </li>-->
  </ul>
</template>

<script>
export default {
  name: 'BreadcrumbsComponent',
  props: ['breadCrumbs'],
  data() {
    return {
      activeClass: 'breadcrumbs-link breadcrumbs-link-active',
      linkClass: 'breadcrumbs-link',
    };
  },
  methods: {
    getLinkForClass(breadCrumb) {
      if (breadCrumb.isActive) {
        return this.activeClass;
      }
      return this.linkClass;
    },
  },
};
</script>

<style>
/*------------------ BREADCRUMBS -------------------*/
.breadcrumbs {
  /*margin: 0.4rem -10rem 0.5rem;*/
  padding: 0.7rem;
  font-family: sans-serif;
}

.breadcrumbs-item {
  display: inline-block;
}

.breadcrumbs-item:not(:last-of-type)::after {
  content: '|';
  margin: 0 5px;
  color: var(--color-dark);
}

.breadcrumbs-link {
  text-decoration: none;
  color: var(--color-dark);
  font-weight: 300;
}

.breadcrumbs-link:hover {
  color: var(--color-fulvous);
}

.breadcrumbs-link-active {
  color: var(--color-black);
  font-weight: 400;
}

</style>
