<template>
    <component :is="questionIcType" :key="getIcQuestionnaireIndex"/>
</template>

<script>
import AddIntegrativeComplexityValues
  from '@/components/Home/QuestionnaireComponent/AddIntegrativeComplexityValues.vue';
import RateIntegrativeComplexityValue
  from '@/components/Home/QuestionnaireComponent/RateIntegrativeComplexityValue.vue';

export default {
  name: 'IntegrativeComplexityQuestion',
  components: {
    AddIntegrativeComplexityValues,
    RateIntegrativeComplexityValue,
  },
  data() {
    return {
      question: null,
    };
  },
  computed: {
    questionIcType() {
      return this.$store.getters.getSelectedIcQuestionType;
    },
    getIcQuestionnaireIndex() {
      return this.$store.getters.getIcQuestionnaireIndex;
    },
  },
  methods: {},
};
</script>

<style scoped>
</style>
