import axios from 'axios';
import { BlobServiceClient } from '@azure/storage-blob';

export default class NetworkAuth {
  static login(data, config) {
    return axios.post('user/authentication', data, config);
  }

  static registerUser(data, config) {
    return axios.post('user', data, config);
  }

  static updateProfile(data, config) {
    return axios.post('user/update', data, config);
  }

  static getTags(config) {
    return axios.get('content/tags/getTags', config);
  }

  static getTagQuestions(config) {
    return axios.get('content/tags/getTagQuestions', config);
  }

  static getMe(config) {
    return axios.get('user/informations', config);
  }

  static uploadPicBlob(blob, fileUrl) {
    const sasString = process.env.VUE_APP_AZURE_STORAGE_SAS_TOKEN;
    const containerName = process.env.VUE_APP_AZURE_CONTAINER_NAME;
    const resourceName = process.env.VUE_APP_AZURE_STORAGE_RESOURCE_NAME;
    const url = `https://${resourceName}.blob.core.windows.net/?${sasString}`;
    const blobServiceClient = new BlobServiceClient(url);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // get file name from url
    const splitString = fileUrl.split('/');
    const name = splitString[splitString.length - 1];

    const blockBlobClient = containerClient.getBlockBlobClient(name);
    const options = { blobHTTPHeaders: { blobContentType: blob.type } };
    return blockBlobClient.uploadData(blob, options);
  }

  static validateRegisterData(data, config) {
    return axios.post('user/validateRegistration', data, config);
  }

  static checkIfUserLoggedIn(data, config) {
    return axios.post('ping/token', data, config);
  }

  static requestPasswordReset(data, config) {
    return axios.post('user/reqresetpwd', data, config);
  }

  static setNewPassword(data, config) {
    return axios.post('user/resetpwd', data, config);
  }

  static activateAccount(data, config) {
    return axios.post('user/accountactivation', data, config);
  }
}
