<template>
  <breadcrumbs-component :breadCrumbs="knowledgeDbBreadCrumbs"/>
  <div class="knowledge-container ">
    <div class="title">
      <h1 id="txt_title">
        {{ $t('KnowledgeDatabaseComponent.allYouNeedToKnow') }}
      </h1>
    </div>
    <div class="knowledge-content">
      <div class="infotext">
        <h2 id="txt_infotextTitle">
          {{ $t('KnowledgeDatabaseComponent.TheOMindset') }}
        </h2>
        <p v-html="$t('KnowledgeDatabaseComponent.infotextParagraph')" id="txt_infotextParagraph">
        </p>
        <h3 id="txt_h3">
          {{ $t('KnowledgeDatabaseComponent.ThatMeans') }}
        </h3>
        <p>
          <span id="txt_diagramDescription">
            {{ $t('KnowledgeDatabaseComponent.diagramDescription') }}
          </span>
          <br>
          <span id="txt_diagram">
             {{ $t('KnowledgeDatabaseComponent.diagram') }}
          </span>
        </p>
        <ol class="list">
          <li id="txt_open">
            {{ $t('KnowledgeDatabaseComponent.open') }}
          </li>
          <li id="txt_creativ">
            {{ $t('KnowledgeDatabaseComponent.creativ') }}
          </li>
          <li id="txt_fail">
            {{ $t('KnowledgeDatabaseComponent.fail') }}
          </li>
          <li id="txt_toSharing">
            {{ $t('KnowledgeDatabaseComponent.toSharing') }}
          </li>
          <li id="txt_toSourcing">
            {{ $t('KnowledgeDatabaseComponent.toSourcing') }}
          </li>
          <li id="txt_ic">
            {{ $t('KnowledgeDatabaseComponent.ic') }}
          </li>
        </ol>
        <p>
          <span id="txt_results">
            {{ $t('KnowledgeDatabaseComponent.results') }}
          </span>
          <br>
          <span id="txt_results2">
            {{ $t('KnowledgeDatabaseComponent.results2') }}
          </span>
        </p>
        <p id="txt_resultParagraph">
          {{ $t('KnowledgeDatabaseComponent.resultParagraph') }}
        </p>
        <p id="txt_basis">
          {{ $t('KnowledgeDatabaseComponent.basis') }}
        </p>
        <p id="txt_reminder">
          {{ $t('KnowledgeDatabaseComponent.reminder') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';

export default {
  name: 'KnowledgeDatabaseComponent',
  components: {
    BreadcrumbsComponent,
  },
  data() {
    return {
      knowledgeDbBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/knowledge-database',
          isActive: false,
          name: this.$t('BreadCrumbs.knowledgeDatabase'),
        },
        {
          goTo: '/knowledge-database',
          isActive: true,
          name: this.$t('BreadCrumbs.omindset'),
        },
      ],
    };
  },
};
</script>

<style scoped>
/*------------------ CONTENT -------------------*/
.knowledge-container {
  margin: 0 auto;
  position: relative;
}

.knowledge-content {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  max-width: 960px;
  margin: 0 auto;
}

.title {
  padding: 20px 0.7rem;
}

.infotext {
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-light);
}

h3 {
  padding-top: 9px;
}

.list {
  padding: 0 2rem 1rem;
}

.list li {
  list-style-type: decimal;
  padding: 0.1rem 0.5rem;
}

.list li::marker {
  color: var(--color-orange);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .breadcrumbs {
    margin: -1rem 0;
  }

  .title {
    margin: 1rem 0;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .breadcrumbs {
    margin: -1rem 0;
  }

  .title {
    margin: 1rem 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .breadcrumbs {
    margin: 0;
  }

  .title {
    margin: 0 0 1rem;
  }
}

@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 -10rem -1rem;
  }

  .title {
    margin: 0rem -10rem 1rem;
  }
}

</style>
