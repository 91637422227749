import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function checkIfRegisterSuccessful(to, from, next) {
  if (store.state.auth.registrationSuccessful) {
    next();
  } else {
    next('/auth/register');
  }
}
