import axios from 'axios';

export default class NetworkContent {
  static getTeams(config) {
    return axios.get('content/getTeams', config);
  }

  static async getTeamspace(config) {
    return axios.get('content/getTeamspace', config);
  }

  static async getAssessmentsOverview(config) {
    return axios.get('assessment/getAssessmentOverview', config);
  }

  static async getParticipants(config) {
    return axios.get('content/ksscreator/getUser', config);
  }

  static async getArchivedTasks(config) {
    return axios.get('content/ksscreator/getArchiv', config);
  }

  static async getCreatedTasks(config) {
    return axios.get('content/ksscreator/getAlreadyCreated', config);
  }

  static async createKssTask(data, config) {
    return axios.post('assessment/kss/create', data, config);
  }

  static async deleteKssTask(data, config) {
    return axios.post('assessment/kss/delete', data, config);
  }

  static getLeadedTeams(config) {
    return axios.get('content/getLeadedTeams', config);
  }

  static getAllUsers(config) {
    return axios.get('content/getAllUser', config);
  }

  static getPersonalMindset(config) {
    return axios.get('content/dashboard/personalMindset', config);
  }

  static getPersonalMindsetPlot(data, config) {
    return axios.post('content/dashboard/personalMindsetPlot', data, config);
  }

  static getNotifications(config) {
    return axios.get('notification/allPopups', config);
  }

  static deactivatePopUp(id, config) {
    return axios.post('notification/deactivatePopup', { id }, config);
  }

  static subscribeToPush(data, config) {
    return axios.post('notification/subscribe', data, config);
  }

  static getDashboardProfileDesc(config) {
    return axios.get('content/dashboard/profileDescription', config);
  }

  static async getPersonalKssPlot(config) {
    return axios.get('content/dashboard/personalKSSPlot', config);
  }

  static getTeamDashboard(config) {
    return axios.get('content/teamDashboard/dashboardData', config);
  }

  static getTeamDashboardPlots(data, config) {
    return axios.post('content/teamDashboard/dashboardDataPlot', data, config);
  }
}
