import { createApp } from 'vue';

import axios from '@/includes/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './includes/i18n';
import VeeValidate from './includes/validation';
import FontAwesome from './includes/fontawesome';

const app = createApp(App);
app.use(i18n);
app.use(VeeValidate);
app.use(FontAwesome);
app.use(store);
app.use(axios);
app.use(router);
app.mount('#app');
