<template>
  <div class="notifications">
    <toast-component v-for="(toast, index) in getToastNotifications"
                     :key="index"
                     :index="index"
                     :toast="toast"
                     :id="toast.id"/>
  </div>
</template>

<script>
import ToastComponent from '@/components/global/ToastComponent.vue';

export default {
  name: 'ToastNotificationsComponent.vue',
  components: { ToastComponent },
  computed: {
    getToastNotifications() {
      return this.$store.getters.getToastNotifications;
    },
  },
};
</script>

<style scoped>
.notifications {
  position: fixed;
  right: 10px;
  top: 10px;
  width: 350px;
  z-index: 50;
}

@media screen and (max-width: 430px) {
  .notifications {
    width: 90vw;
  }
}
</style>
