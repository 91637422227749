import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    teams: [],
    selectedTeams: [],
    teamSpace: [],
    assessments: [],
    notifications: [],
    toastNotifications: [],
    refreshValue: Date.now(),
    profileDashboardDesc: null,
  },
  getters: {
    getTeams: (state) => state.teams,
    getNotifications: (state) => state.notifications,
    getToastNotifications: (state) => state.toastNotifications,
    getRefreshValue: (state) => state.refreshValue,
    getProfileDashboardDesc: (state) => state.profileDashboardDesc,
  },
  mutations: {
    setRefreshValue: (state) => {
      state.refreshValue = Date.now();
    },
    setTeams: (state, teams) => {
      if (Array.isArray(teams)) {
        state.teams = teams;
      }
    },
    setSelectedTeams: (state, data) => {
      state.selectedTeams = data;
    },
    toggleTeamSelection: (state, teamId) => {
      const indexToDelete = state.selectedTeams.indexOf(teamId);
      if (indexToDelete > -1) {
        state.selectedTeams.splice(indexToDelete, 1);
      } else {
        state.selectedTeams.push(teamId);
      }
    },
    setProfileDashboardDesc: (state, data) => {
      state.profileDashboardDesc = data;
    },
    setTeamspace: (state, data) => {
      data.forEach((el) => {
        state.teamSpace.push(el);
      });
    },
    setAssessments: (state, data) => {
      state.assessments = data;
    },
    setNotifications: (state, data) => {
      state.notifications = data;
    },
    setToastNotification: (state, data) => {
      state.toastNotifications.push(data);
    },
    removeTeams: (state) => {
      state.teams = [];
    },
    removeTeamspace: (state) => {
      state.teamSpace = [];
    },
    removeAssessments: (state) => {
      state.assessments = [];
    },
    removeToastNotification: (state, id) => {
      const index = state.toastNotifications.findIndex((el) => el.id === id);
      state.toastNotifications.splice(index, 1);
    },
  },
  actions: {
    async getTeams({ commit }) {
      const { data } = await NetworkContent.getTeams();
      commit('setTeams', data.teams);
    },
    async getTeamspace({ commit }) {
      const { data } = await NetworkContent.getTeamspace();
      commit('setTeamspace', data.data);
    },
    async getAssessmentsOverview({ commit }) {
      const { data } = await NetworkContent.getAssessmentsOverview();
      commit('setAssessments', data);
    },
    async getNotifications({ commit }) {
      const { data } = await NetworkContent.getNotifications();
      commit('setNotifications', data.popups);
    },
    async deactivatePopUp({ commit }, id) {
      await NetworkContent.deactivatePopUp(id);
      const { data } = await NetworkContent.getNotifications();
      commit('setNotifications', data.popups);
    },
    async getDashboardProfileDescription({ commit }) {
      const { data } = await NetworkContent.getDashboardProfileDesc();
      commit('setProfileDashboardDesc', data.data);
    },
  },
};
