import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function checkIfUserHasPermission(to, from, next, permission) {
  let hasPermision = false;
  permission.forEach((el) => {
    if (store.state.auth.user.permissionsFlags[el]) {
      hasPermision = true;
    }
  });

  if (hasPermision) {
    next();
  } else {
    next('/');
  }
}
