import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    leftSelection: [],
    rightSelection: [],
    isDashboardLoading: false,
    oimPlotData: null,
    kssPlotData: null,
    leftSelected: null,
    rightSelected: null,
    // Team Dashboard
    oimTeamPlotData: null,
    kssTeamPlotData: null,
    teamSelection: [],
    selectedTeam: null,
  },
  getters: {
    getOimPlotData: (state) => state.oimPlotData,
    getKssPlotData: (state) => state.kssPlotData,
    getOimTeamPlotData: (state) => state.oimTeamPlotData,
    getKssTeamPlotData: (state) => state.kssTeamPlotData,
    getIsDashboardLoading: (state) => state.isDashboardLoading,
  },
  mutations: {
    setIsDashboardLoading: (state, data) => {
      state.isDashboardLoading = data;
    },
    setLeftSelection: (state, data) => {
      state.leftSelection = data.filter((el) => el.show.includes('left'));
    },
    // for init commit with no data
    // for set commit with data set to value
    setLeftSelected: (state, data) => {
      if (data) {
        state.leftSelected = data;
      } else {
        const [first] = state.leftSelection;
        state.leftSelected = first;
      }
    },
    // for init commit with no data
    // for set commit with data set to value
    setRightSelected: (state, data) => {
      if (data) {
        state.rightSelected = data;
      } else {
        const [first] = state.rightSelection;
        state.rightSelected = first;
      }
    },
    setRightSelection: (state, data) => {
      state.rightSelection = data.filter((el) => el.show.includes('right'));
    },
    setOimPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        state.oimPlotData = data;
      }
    },
    setKssPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        state.kssPlotData = data;
      }
    },
    setTeamSelection: (state, data) => {
      state.teamSelection = data;
    },
    // for init commit with no data
    // for set commit with data set to value
    setSelectedTeam: (state, data) => {
      if (data) {
        state.selectedTeam = data;
      } else {
        const [first] = state.teamSelection;
        state.selectedTeam = first;
      }
    },
    setOimTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        state.oimTeamPlotData = data;
      }
    },
    setKssTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        state.kssTeamPlotData = data;
      }
    },
    resetPlotData: (state) => {
      state.oimPlotData = null;
      state.kssPlotData = null;
      state.oimTeamPlotData = null;
      state.kssTeamPlotData = null;
    },
    resetPersonalKssPlot: (state) => {
      state.kssPlotData = null;
    },
    resetPersonalMindsetPlot: (state) => {
      state.oimPlotData = null;
    },
  },
  actions: {
    async getPersonalMindset({ commit }) {
      const { data } = await NetworkContent.getPersonalMindset();
      commit('setLeftSelection', data.data);
      commit('setRightSelection', data.data);
      commit('setOimPlotData', data.plotData);
      commit('setLeftSelected');
      commit('setRightSelected');
    },
    async getPersonalMindsetPlot({
      commit,
      state,
    }) {
      commit('resetPersonalMindsetPlot');
      const postData = {
        data: [state.leftSelected, state.rightSelected],
      };
      const { data } = await NetworkContent.getPersonalMindsetPlot(postData);
      commit('setOimPlotData', data.plotData);
    },
    async getPersonalKssPlot({ commit }) {
      commit('resetPersonalKssPlot');
      const { data } = await NetworkContent.getPersonalKssPlot();
      commit('setKssPlotData', data.plotData);
    },
    async getTeamDashboard({ commit }) {
      const { data } = await NetworkContent.getTeamDashboard();
      commit('setOimTeamPlotData', data.plotData.mindset_omindset);
      commit('setKssTeamPlotData', data.plotData.kss_kss);
      commit('setTeamSelection', data.data);
      commit('setSelectedTeam');
    },
    async getTeamDashboardPlots({
      commit,
      state,
    }) {
      commit('resetPlotData');
      const postData = {
        data: state.selectedTeam,
      };
      const { data } = await NetworkContent
        .getTeamDashboardPlots(postData);
      commit('setOimTeamPlotData', data.plotData.mindset_omindset);
      commit('setKssTeamPlotData', data.plotData.kss_kss);
    },
  },
};
