import NetworkAuth from '@/helpers/networking/NetworkAuth';
import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export async function checkIfUserLoggedIn(to, from, next) {
  const config = {
    headers: {
      Authorization: store.state.auth.token,
    },
  };
  try {
    const { data } = await NetworkAuth.checkIfUserLoggedIn({}, config);
    store.commit('updatePermissionFlags', data.permissionFlags);
    next();
  } catch (error) {
    next('/auth');
  }
}
