import NetworkContent from '@/helpers/networking/NetworkContent';
import NetworkTeammanagement from '@/helpers/networking/NetworkTeammanagement';

export default {
  state: {
    tmLeadedTeams: [],
    tmAllUsers: [],
  },
  getters: {},
  mutations: {
    setLeadedTeams: (state, data) => {
      data.forEach((el) => {
        state.tmLeadedTeams.push({
          _id: el._id,
          name: el.name,
          leaders: el.leaders,
          members: el.members,
        });
      });
    },
    setAllUsers: (state, data) => {
      data.forEach((el) => {
        state.tmAllUsers.push({
          _id: el._id,
          firstName: el.firstName,
          lastName: el.lastName,
          name: el.name,
        });
      });
    },
    setNewTeam: (state, name) => {
      state.tmLeadedTeams.unshift({
        _id: null,
        name,
        leaders: [],
        members: [],
      });
    },
    resetTeamManagement: (state) => {
      state.tmAllUsers = [];
      state.tmLeadedTeams = [];
    },
  },
  actions: {
    async getLeadedTeams({ commit }) {
      const { data } = await NetworkContent.getLeadedTeams();
      commit('setLeadedTeams', data.data);
    },
    async getAllUsers({ commit }) {
      const { data } = await NetworkContent.getAllUsers();
      commit('setAllUsers', data.data);
    },
    async updateTeam(_, team) {
      await NetworkTeammanagement.updateTeam(team);
    },
    async createTeam(_, team) {
      await NetworkTeammanagement.createTeam(team);
    },
    async deleteTeam(_, team) {
      await NetworkTeammanagement.deleteTeam(team);
    },
  },
};
