<template>
  <!----------------- CREATOR ----------------->
  <div class="inputs">
    <div class="input-field">
      <vee-field v-model="subjectComputed"
                 type="text"
                 class="form-control"
                 name="subject"
                 id="txt_subject"/>
      <label class="form-control inputlabel" id="lbl_mtgSubject">
        {{ $t('KssFormComponent.addMeetingSubject') }}
      </label>
    </div>
    <div class="input-field">
      <vee-field v-model="descriptionComputed"
                 type="text"
                 class="form-control"
                 name="description"
                 id="txt_description"/>
      <label class="form-control inputlabel"
             id="lbl_mtgDescription"> {{ $t('KssFormComponent.addDescription') }}</label>
    </div>

    <!----------------- ADD TEAMS ----------------->
    <div class="select-container">
      <div class="teams-container" id="btn_selectTeam" @click.prevent="toggleTeams">
                    <span class="circle-plus">
                      <font-awesome-icon icon="fa-circle-plus"/>
                    </span>
        <span class="btn-text" id="btn_textTeam">{{ $t('KssFormComponent.addTeams') }}</span>
      </div>

      <kss-team-selection-component v-if="showTeams"/>
    </div>

    <!----------------- ADD PARTICIPANTS ----------------->
    <div class="select-container">
      <div class="participants-container" id="btn_select" @click.prevent="toggleParticipants">
        <span class="circle-plus">
          <font-awesome-icon icon="fa-circle-plus"/>
        </span>
        <span class="btn-text" id="btn_textParticipants">{{ participantsText }}</span>
      </div>
      <kss-participants-selection-component v-if="showParticipants"/>
    </div>
    <!-------------- ADD EXTERNAL PARTICIPANTS ----------->
    <div class="select-container">
      <div class="email-container" id="email_invite" @click.prevent="toggleEmailInvite">
            <span class="circle-plus">
              <font-awesome-icon icon="fa-circle-plus"/>
            </span>
        <span class="btn-text" id="email_inviteParticipants">
          {{ $t('KssEmailInviteComponent.externalInvites') }}
        </span>
      </div>
      <kss-email-invite-component v-if="showEmailInvite"/>
    </div>
  </div>
</template>

<script>
import KssTeamSelectionComponent from '@/components/Home/KssCreator/KssTeamSelectionComponent.vue';
import KssParticipantsSelectionComponent
  from '@/components/Home/KssCreator/KssParticipantsSelectionComponent.vue';
import KssEmailInviteComponent from '@/components/Home/KssCreator/KssEmailInviteComponent.vue';

export default {
  name: 'KssFormComponent',
  components: {
    KssEmailInviteComponent,
    KssParticipantsSelectionComponent,
    KssTeamSelectionComponent,
  },
  data() {
    return {
      showTeams: false,
      showParticipants: false,
      showEmailInvite: false,
    };
  },
  computed: {
    participantsText() {
      const count = this.$store.getters.getSelectedKssParticipantCount;
      if (count > 0) {
        return `${count} ${this.$t('KssFormComponent.selected')}`;
      }
      return this.$t('KssFormComponent.addParticipants');
    },
    subjectComputed: {
      get() {
        return this.$store.state.kss.newMeetingSubject;
      },
      set(val) {
        this.$store.commit('setNewSubject', val);
      },
    },
    descriptionComputed: {
      get() {
        return this.$store.state.kss.newDescription;
      },
      set(val) {
        this.$store.commit('setNewDescription', val);
      },
    },
  },
  methods: {
    toggleTeams() {
      this.showTeams = !this.showTeams;
    },
    toggleParticipants() {
      this.showParticipants = !this.showParticipants;
    },
    toggleEmailInvite() {
      this.showEmailInvite = !this.showEmailInvite;
    },
  },
};
</script>

<style scoped>
/*---------------- CONTENT NEW: CREATOR ------------------*/
.inputs {
  margin-top: 1.5rem;
}

input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all .3s;
  height: 3.5rem;
}

.inputs .inputlabel {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all .3s;
  padding-left: var(--card-padding);
}

.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all .5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

/*------------------ ADD PARTICIPANTS -------------------*/
.select-container {
  position: relative;
  width: 100%;
}

.teams-container {
  display: flex;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  padding-bottom: 8px;
}

.teams-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  padding: 0.5rem 0 0.1rem var(--card-padding);
}

.teams-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  padding: 0.7rem var(--card-padding) 0.1rem;
}

.teams-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.teams-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.teams-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.participants-container {
  display: flex;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  padding-bottom: 8px;
}

.participants-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  padding: 0.5rem 0 0.1rem var(--card-padding);
}

.participants-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  padding: 0.7rem var(--card-padding) 0.1rem;
}

.participants-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.participants-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.participants-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.email-container {
  display: flex;
  margin-top: 10px;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  padding-bottom: 8px;
}

.email-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  padding: 0.5rem 0 0.1rem var(--card-padding);
}

.email-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  padding: 0.7rem var(--card-padding) 0.1rem;
}

.email-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.email-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.email-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: none;
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*.item.checked .check-icon{
    transform: scale(1);
}*/

</style>
