<template>
  <ul class="list-items" id="tpl_teams-container">
    <li :class="{ item: !team.isChecked,
             'item checked': team.isChecked}"
        v-for="(team, index) in teams" :key="team"
        @click.prevent="checkElement(index)">
              <span class="item-text">
                {{ team.name }}
              </span>
      <span class="checkbox">
        <i class="check-icon">
          <font-awesome-icon icon="fa-circle-check"/>
        </i>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'KssTeamSelectionComponent',
  computed: {
    teams() {
      return this.$store.getters.getKssTeams;
    },
  },
  methods: {
    checkElement(index) {
      this.$store.commit('updateKssTeamSelection', index);
    },
  },
};
</script>

<style scoped>

.list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.select-container .list-items {
  width: 100%;
  max-height: 250px;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.select-container .list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.select-container .list-items .item:hover {
  transform: scale(1.02);
}

.select-container .list-items .item .item-text {
  display: flex;
  align-items: center;
}

.select-container .list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.select-container .list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.item.checked .check-icon {
  transform: scale(1);
}
</style>
