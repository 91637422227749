<template>
  <div class="check-button" @click="handleChange(value)">
    <font-awesome-icon icon="fa-square-check" v-if="checked"/>
  </div>
</template>

<script>
import { useField } from 'vee-validate';

export default {
  name: 'CheckboxComponent',
  props: {
    modelValue: {
      type: null,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  created() {
    this.field = useField(this.name, undefined, {
      type: 'checkbox',
      checkedValue: true,
      uncheckedValue: false,
    });
    this.checked = this.field.value;
  },
  watch: {
    checked(value) {
      this.field.value = value;
    },
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
    },
  },
};
</script>

<style scoped>
.check-sms .check-button {
  display: flex;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 4px;
  padding-right: 10px;
  margin-right: 10px;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.check-sms .check-button .fa-square-check {
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.check-sms.checked .check-button .fa-square-check {
  color: var(--color-orange);
  font-size: 22px;
  padding-left: 10px;
  transform: scale(1);
}
</style>
