import NetworkContent from '@/helpers/networking/NetworkContent';

const publicVapidKey = process.env.VUE_APP_PUBLICVAPIDKEY;

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String) => {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
async function checkServiceAvailable() {
  return ('serviceWorker' in navigator);
}

async function checkSubscription() {
  const serviceWorker = await navigator.serviceWorker.register('../push_serviceworker.js', {
    scope: '../',
  });

  serviceWorker.update();

  let registrations = await navigator.serviceWorker.getRegistrations();

  if (registrations.length === 0) {
    await navigator.serviceWorker.register('../sw.js', {
      scope: '../',
    });
    registrations = await navigator.serviceWorker.getRegistrations();
  }

  let subscription = await registrations[0].pushManager.getSubscription();

  if (subscription == null) {
    subscription = await registrations[0].pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });
    await NetworkContent.subscribeToPush({ subscription });
  }

  return subscription;
}

export { checkServiceAvailable, checkSubscription };
