import axios from 'axios';
import store from '@/store';

export default {
  install() {
    axios.defaults.baseURL = process.env.VUE_APP_OMIND_BACKEND;
    const token = store.state.auth.token ?? localStorage.getItem('jwt');
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
  },
};
